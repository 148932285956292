<template>
	<UTooltip text="Benjamin Canac">
		<UChip inset :color="user.status === undefined || user.status === null ? 'neutral' : user.status === 'online' ? 'success' : 'warning'">
			<UAvatar :size="size ? size : 'md'" :alt="user.name" />
		</UChip>
</UTooltip>
</template>

<script setup lang="ts">
const props = defineProps<{ user: any, size?: "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "3xs" | "2xl" | "3xl" | undefined }>()
const user = ref(props.user)

if (user.value?.user_presence) {
	//check if user.user_presence.last_activity is within 15 mins, if so, user.status = 'online'
	const lastActivity = new Date(user.value.user_presence.last_activity)
	const fifteenMinutesAgo = new Date(Date.now() - 15 * 60 * 1000)
	const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000)
	user.value.status = lastActivity > fifteenMinutesAgo ? 'online' : lastActivity > oneHourAgo ? 'away' : null
}
</script>